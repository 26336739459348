import React from 'react';
import Seo from "../seo";
import BaseContainer from "../components/common/BaseContainer";
import {Grid, Toolbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Link as GatsbyLink} from "gatsby";
import {FiberpayLogoImageCobalt} from "../components/common/FiberpayLogoImage";
import Button from "../components/common/Button";
import {Box} from "@mui/system";
import Link from "../components/common/Link";
import Footer from "../components/common/Footer";

const PrivacyPolicy = () => {
  const theme = useTheme();
  const loginButtonSize = useMediaQuery(theme.breakpoints.up("sm")) ? 'medium' : 'small';

  return (
    <>
      <Seo title='Opis usług i regulaminy'/>
      <header>
        <Toolbar disableGutters component='nav' sx={{bgcolor: 'transparent', py: 2}}>
          <BaseContainer>
            <Box display='flex' justifyContent='space-between'>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <GatsbyLink to='/'>
                  <FiberpayLogoImageCobalt/>
                </GatsbyLink>
              </Box>
              <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                <Button as={GatsbyLink} to='/panel' size={loginButtonSize} variant={'contained'}>
                  Konto
                </Button>
              </Box>
            </Box>
          </BaseContainer>
        </Toolbar>
      </header>
      <main style={{minHeight: '70vh'}}>
        <BaseContainer>
          <Box py={4}>
            <Box pb={4}>
              <Typography variant='h4'>
                Polityka prywatności
              </Typography>
            </Box>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <Typography color='primary.main'>
                  Przywiązujemy dużą wagę do poszanowania Twojej prywatności
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Korzystając z naszych usług, powierzasz nam swoje dane. Zapewniamy ich bezpieczeństwo oraz
                  umożliwiamy Ci kontrolę nad nimi. W naszym dokumencie Polityki prywatności znajdziesz informację o
                  tym, jakie prawa Ci przysługują, jakie dane zbieramy, w jakim celu oraz jak możesz je aktualizować i
                  usuwać oraz nimi zarządzać.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Link as='a' target='_blank'
                      rel='noopener noreferrer'
                      href='/FiberPay_Polityka_Prywatności_20200818.pdf'
                >
                  Pobierz (PDF)
                </Link>
              </Grid>
            </Grid>
          </Box>
        </BaseContainer>
      </main>
      <Footer />
    </>
  )
}

export default PrivacyPolicy;
